import MuiSelect from '@mui/material/Select';
import { styled } from '@mui/material/styles';

export const StyledWrapper = styled('div')<{ hasError?: boolean }>(({ theme, hasError }) => (hasError ? {
  '& .MuiFormHelperText-root': {
    color: theme.palette.error.main,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.error.main,
  },
} : {}));

export const Select = styled(
  MuiSelect,
  { shouldForwardProp: (props) => props !== 'isEmpty' },
)<{ isEmpty: boolean }>(({ isEmpty, theme }) => ({
  '& .MuiSelect-select': {
    color: isEmpty ? theme.palette.general.lightGrey9 : theme.palette.text.primary,
  },
}));
