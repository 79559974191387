const palette = {
  primary: {
    main: '#0061AF',
    secondary: '#005ebd',
    light: '#4ea3f6',
  },
  secondary: {
    main: '#878686',
    secondary: '#878686',
    light: '#878686',
  },
  error: {
    main: '#f44336',
    secondary: '#d32f2f',
    light: '#f15642',
  },
  success: {
    main: '#2ac1ab',
    secondary: '#46d7cb',
    light: '#46D7CBFF',
  },
  background: {
    main: '#ffffff',
    secondary: '#F5F5F5'
  },
  text: {
    primary: '#000000',
    secondary: '#677179',
  },
  input: {
    border: '#c3cbd2',
    text: '#677179',
  },
  border: {
    main: '#e0e6ed',
  },
  avatar: {
    main: '#e0e6ed',
  },
  subheaderBackground: {
    main: '#EBE1D6',
  },
  headerBackground: {
    main: '#ffffff',
  },
  link: {
    main: '#0F69EF',
  },
  general: {
    lightGrey: '#d7d7d7',
    lightGrey1: '#a3adb5',
    lightGrey2: '#677179',
    lightGrey3: '#c5c5c5',
    lightGrey4: '#697178',
    lightGrey5: '#E1E6EE80',
    lightGrey6: '#F2F2F2',
    lightGrey7: '#D8DCE3',
    lightGrey8: '#e4e4e4',
    lightGrey9: '#aeaeae',
    darkYellow1: '#fdc749',
    lightYellow1: '#f5e990',
    lightYellow2: '#FFD580',
    darkGreen1: '#34A30D',
    darkGreen2: '#10ad28',
    lightGreen: '#69B47D',
    lightGreen1: '#90EE90',
    lightBlue: '#46B0E8',
    lightBlue1: '#2F78EA',
    lightOrange: '#ED6C4D',
    messageBubble: '#eaf5f4',
    alleavesBackground: '#7dc25c',
  }
};

export default palette;
